<template>
        <footer class="footer">
          <div class="container-fluid">
            <nav class="float-left">
              <ul>
                <li>
                  <a href="https://www.facebook.com/pod.electronics.ly">
                    فيسبوك
                  </a>
                </li>
 

              </ul>
            </nav>
            <div class="copyright float-right">
              جميع الحقوق محفوظة
                &copy; 
              {{getCurrentYear}}
            </div>
          </div>
        </footer>
</template>

<script>
export default {
computed:{
        getCurrentYear(){
          var currentYear= new Date().getFullYear(); 
          return currentYear;
        }
  }
}
</script>

<style>

</style>