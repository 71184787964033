<template>
<div>
  
  <!-- End Google Tag Manager (noscript) -->
  <div class="wrapper ">
    
    <SideBar/>
    <div class="main-panel">
      
      <!-- Navbar -->
       <Header/>
      <!-- End Navbar -->
      <div class="content" style="margin-top:100px">
       
         <router-view />
      </div>
         <!-- Footer -->
         <Footer/>
      </div>
    </div>
      <!-- Plugin -->
      <!-- <Plugin/> -->
  </div>
</template>

<script>
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
 import Header from '../../components/Header';
import { mapState} from 'vuex';
import store from '../../store/index';



export default {
  name:'Main',
    data() 
  {
    return {

    }
   },
    computed:{
      ...mapState({
           user: state => state.admin.users.AuthUser,     
        }),

    },  
    methods:{

    },
components:{
         Footer,         
         SideBar,
         Header,
         SideBar,
     },
     created(){

     },
     beforeRouteEnter(to, from, next) {
       store.commit('admin/PleaseStopLoading');
        store.dispatch('admin/fetchAuthUser')
        .then((response) => {
             next(true);
        })
		.catch((error) => {
			next(false);
		});
    },  
    beforeDestroy() {
     clearInterval(this.interval);
}  
}
</script>

<style >
.main-panel>.content {
    margin-top: 70px;
    padding: 30px 0px;
    min-height: calc(100vh - 123px);
}
.img-style{
   width: 70px;
   margin-bottom: 20px;
}

</style>



