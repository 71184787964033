<template>
    <div class="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">
      <div class="logo">
        <a>
          <img src="/assets/img/logo.png"  alt="logo" class="center-logo">
         <!-- <span style="font-family: Phenomena !important;font-weight: 600;font-size: 24px;">PODE</span> -->
        </a>
        
      </div>
      <div class="sidebar-wrapper">
        <ul class="nav">
            <NavSingle MainTitle="داشبورد" Path="admin.dashboard.home" Icon="dashboard" :Active="true"/>
            <NavGroup  v-if="manageService('manage_colors') ||
                             manageService('manage_countries') ||
                             manageService('manage_cities') ||
                             manageService('manage_status')" MainId="Applications" :Items="ApplicationItems" Icon="games" MainTitle="إدارة التطبيق"/>
           
            <NavSingle v-if="manageService('manage_advertisement')" MainId="advertisements" Path="admin.dashboard.advertisement.list" Icon="crop_original" MainTitle="الاعلانات"/>
            <NavSingle v-if="manageService('manage_categories')" MainId="Categories" Path="admin.dashboard.categories.list" Icon="category" MainTitle="التصنيفات"/>
            <NavSingle v-if="manageService('manage_products')" MainId="Products" Path="admin.dashboard.products.list" Icon="production_quantity_limits" MainTitle="المنتجات"/>
            <NavSingle v-if="manageService('manage_orders')" MainId="Sales" Path="admin.dashboard.sales.list" Icon="receipt" MainTitle="الطلبات"/>
            <NavSingle v-if="manageService('manage_clients')" MainId="clients" Path="admin.dashboard.clients.list" Icon="group" MainTitle="الزبائن"/>
            <NavSingle v-if="manageService('manage_discount_code')" MainId="discount" Path="admin.dashboard.discount.list" Icon="local_offer" MainTitle="اكواد الخصم"/>
            <NavSingle v-if="manageService('manage_users')" MainTitle="إدارة المستخدمين" Path="admin.dashboard.users.list" Icon="person"/>

            <NavSingle  MainTitle="تسجيل الخروج " Icon="exit_to_app" Path="admin.login"  @logout="logout()" />

        </ul>
      </div>
    </div>
</template>

<script>
import NavGroup from './NavGroup';
import NavSingle from './NavSingle';
import store from '../store/index';

export default {
    methods:{
  logout(){
      this.$store.dispatch("admin/authLogout").then(() => {
              this.$router.push({ name: "admin.login" });
      });
  },
    },
computed:{
  
    ApplicationItems(){
      var data= [];
       if(this.manageService('manage_colors')){
          data.push({
                'name':'ادارة الالوان',
                'path':'admin.dashboard.colors.list'
            });
        }
        if(this.manageService('manage_countries')){
          data.push({
              'name':'ادارة الدول',
              'path':'admin.dashboard.countries.list'
          });
        }
        if(this.manageService('manage_cities')){
          data.push({
              'name':'ادارة المدن',
              'path':'admin.dashboard.cities.list'
          });
        }
        if(this.manageService('manage_status')){
          data.push({
              'name':'إدارة الحالات',
              'path':'admin.dashboard.status.list'
          });
        }

         if(this.manageService('manage_settings')){
          data.push({
                'name':'ادارة الاعدادات',
                'path':'admin.dashboard.settings'
            });
        }

        return data;
    },


    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
      },
    },
components:{
    NavGroup,
    NavSingle
}
}
</script>

<style>
.center-logo{
    width: 180px;
    height: 44px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 991px){
  .off-canvas-sidebar nav .navbar-collapse:before, .sidebar:before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #fafafa;
    display: block;
    content: "";
    z-index: 1;
}
}
.logo{
      text-align: center;
}
.nav li a,.nav li a p{
    font-size: 16px !important;
    font-weight: 500 !important;
}
.sidebar .nav li .dropdown-menu a, .sidebar .nav li a{
    font-family: 'Tajawal', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.sidebar[data-color="purple"] li.active>a {
  background-color: #16918b;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(52, 182, 244, 0.40) !important;
}
</style>