<template>
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div class="container-fluid">

           <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
          </button> 
           <a class="navbar-brand back" @click="back()">
              <i class="material-icons">arrow_forward</i>
            </a>
          <div class="collapse navbar-collapse justify-content-end">

            <ul class="navbar-nav">
              <li class="nav-item" >
                <a class="nav-link" href="javascript:void(0)">
                   {{user.name}}
                   
                </a>
              </li>
 
              <li class="nav-item" @click="logout()">
                <a class="nav-link" href="javascript:void(0)">
                  <i class="material-icons">exit_to_app</i>
                  <p class="d-lg-none d-md-block">
                    خروج
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
</template>

<script>
import { mapState} from 'vuex';
import store from '../store/index';

export default {
computed:{
      ...mapState({
               
           user: state => state.admin.users.authUser,
        }),

    },  
methods:{
  logout(){
      this.$store.dispatch("admin/authLogout").then(() => {
              this.$router.push({ name: "admin.login" });
      });
  },
  back(){
      this.$router.go(-1);
    },
    SetNotificationsSeen(){
      store.dispatch('admin/setAllNotificationsSeen',this.user.id).then((response)=>{
            store.dispatch('admin/fetchNotifacationDashboardByUser',this.user.id).then((response) => {
           }).catch((error) => {
		       });
          }).catch((error)=>{
        });
    },
    showNotificationDetails(notification){
       this.$parent.showNotifications(notification);
    }
}
}
</script>

<style>
.back{
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #16918b;
  border-radius: 50%;
  text-align: center;
  color: #fff !important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: auto;
    left: 0;
}

.dropdown-menu{
   max-height: 400px;
    overflow-y: auto;
}

.seen{

}
.not-seen{
background: #2196f3;
    color: white !important;
}
</style>